var GPC0003;
var vipCheckGPC0003; //LGCOMSPEED-6(6th)

$(document).ready(function() {
	if(!document.querySelector('.GPC0003')) return false;
	var $obj = $('.GPC0003');
	
	GPC0003 = {
		el: null,
		tab : null,
		opt: {
			infinite: false,
			slidesToShow: 4,
			slidesToScroll: 4,
			arrows : true,
			dots: true,
			listStyle: true, // WA-GPC0003-05
			responsive: [{
				breakpoint: 992,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					arrows : true
				}
			}, {
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows : false
				}
			}],
			prevArrow: carouselOptions.bigAnglePrev, // common.js variable
			nextArrow: carouselOptions.bigAngleNext // common.js variable
		},
		template: null,
		priceTemplateGPC003: null, //LGEITF-604
		init: function(){
			var _this = GPC0003;
			var els = document.querySelectorAll('.GPC0003');
			/* LGEITF-604 Start */
			_this.priceTemplateGPC003 = $('#priceSyncTemplateGPC0003').clone().html();
			$('#priceSyncTemplateGPC0003').remove();
			/* //LGEITF-604 End */
			
			// LGEAF-213 Start : PDP 영역 내, GPC0011(탭 ) 영역이 없을 경우 ID 값 삭제
			if(!$('.GPC0011').length){
				$('div#pdp_recommend').removeAttr('id');
			}
			// LGEAF-213 End

			for (var i = 0; i < els.length; i++) {
				_this.el = els[i];
				_this.tab = _this.el.querySelector('.tabs-type-liner');
				if($(_this.el).find('.contents-template').length>0) _this.template = $(_this.el).find('.contents-template').clone();
				$(_this.el).find('.contents-template').remove();

				// get Recently products
				var _recentlyModelsList = _this.el.querySelector('input[name="recentlyModelsList"]');
				if(_recentlyModelsList) {
					var $nav = $('.navigation');
					var list = ($nav && $nav.hasClass('b2b')) ? getCookie("LG5_B2B_RecentlyView") : getCookie("LG5_RecentlyView"),
						$content = $(_recentlyModelsList).closest(".tabs-cont");
					_recentlyModelsList.value = list ? list : "";

					if(typeof ePrivacyCookies=='undefined' || ePrivacyCookies.get('LGCOM_IMPROVEMENTS')) {
						if(list == undefined) {
							$content.find("form").remove();
						}else {
							$content.find(".no-content").remove();
						}
					} else {
						ePrivacyCookies.view('load', '', $content);
					}

				}

				_this.addEvent();

				if(_this.tab && !$(_this.tab.querySelector('a')).is('.active')) {
					$(_this.tab.querySelector('a')).trigger('click');
				}
				var fid = $(_this.tab.querySelector('a.active')).attr('href').split('#')[1];
				if($('#'+fid).length>0) runBVStaticPLP($('#'+fid));
			}
			// 20200406 START 이상현 - tab ui의 관계 정보 추가
			_this.addAriaRelationship(els);
			// 20200406 END
			scrollDesign();
		},
		createProductItem: function(productList, productMessages, membershipFlagData){
			var html = [];
			for (var i = 0; i < productList.length; i++) {
				var p = productList[i],
					template = $(GPC0003.template).clone().html();
				//PJTGADL-2 
				var priceValue = "";
				if(p.rPromoPrice != null && p.rPromoPrice != "" && p.rPromoPrice != 'null'){
					priceValue = p.rPromoPrice+"."+nvl(p.rPromoPriceCent,'00');
				} else{
					priceValue = nvl(p.rPrice,'')+"."+nvl(p.rPriceCent,'00');
				}
				//LGEGMC-2592
				var externalLinkTarget = '';
				if(p.externalLinkTarget != null && p.externalLinkTarget != ""){
					externalLinkTarget = p.externalLinkTarget.toLowerCase();
				}
				
				// LGCOMPL-46 Start
				var currencyPosition = $('.GPC0003').find('input[name=currencyPosition]').val()
				var currencySymbol = $('.GPC0003').find('input[name=currencySymbol]').val()
				var obsConvertSubscriptionMonthlyCostArea = '';
				var obsConvertSubscriptionMonthlyCost = changeFormatFullPrice(p.obsConvertSubscriptionMonthlyCost, p.obsConvertSubscriptionMonthlyCostCent)
				if(p.obsConvertSubscriptionMonthlyCost != null && obsConvertSubscriptionMonthlyCost != '0') {
					if(currencyPosition != null) {
						obsConvertSubscriptionMonthlyCostArea = currencyPosition == 'R' ? obsConvertSubscriptionMonthlyCost + "" + currencySymbol
															:	currencyPosition == 'L' ? currencySymbol + "" + obsConvertSubscriptionMonthlyCost
															: '';
						}
				}
				// LGCOMPL-46 End
				
				template = template.replace(/\*modelId\*/g, p.modelId)
								.replace(/\*modelName\*/g, p.modelName)
								.replace(/\*modelDisplayName\*/g, p.modelName.toLowerCase()) // LGEGMC-1766 
								.replace(/\*imageAltText\*/g, (p.imageAltText != null) ? p.imageAltText : '')
								// 20200325 START 박지영 - ufn 따옴표 처리
								// 20200512 START 박지영 - ufn null 처리
								.replace(/\*userFriendlyName\*/g, p.userFriendlyName == null ? '' : p.userFriendlyName.replace(/\"/g, "''"))
								// 20200512 END
								// 20200325 END
								.replace(/\*salesModelCode\*/g, p.salesModelCode)
								.replace(/\*modelUrlPath\*/g, p.modelUrlPath)
								.replace(/\*mediumImageAddr\*/g, p.mediumImageAddr)
								.replace(/\*smallImageAddr\*/g, p.smallImageAddr)
								.replace(/\*productTag1\*/g, p.productTag1)
								.replace(/\*productTag2\*/g, p.productTag2)
								.replace(/\*productTag1UserType\*/g, p.productTag1UserType) // LGEIS-800
								.replace(/\*productTag2UserType\*/g, p.productTag2UserType) // LGEIS-800
								.replace(/\*whereToBuyUrl\*/g, p.whereToBuyUrl)
								.replace(/\*inquiryToBuyUrl\*/g, p.inquiryToBuyUrl)
								.replace(/\*findTheDealerUrl\*/g, p.findTheDealerUrl)
								.replace(/\*promotionText\*/g, p.promotionText ? p.promotionText : "")
								//LGEGMC-2592
								.replace(/\*promotionLinkUrl\*/g, p.promotionLinkUrl ? p.promotionLinkUrl : "")
								.replace(/\*externalLinkTarget\*/g, externalLinkTarget == 'self' ? '_self' : '_blank')
								// PJTOBS 20200703 Start 
								.replace(/\*reStockAlertUrl\*/g, p.reStockAlertUrl ? p.reStockAlertUrl : "")
								// PJTOBS 20200703 End
								// 20200316 START 박지영 : price format 함수 적용
								.replace(/\*rPrice\*\.\*rPriceCent\*/g, p.rPrice ? changeFormatFullPrice(p.rPrice, p.rPriceCent) : 'null')
								// 20200421 START 박지영 : 오타 수정
								.replace(/\*rPromoPrice\*\.\*rPromoPriceCent\*/g, p.rPromoPrice ? changeFormatFullPrice(p.rPromoPrice, p.rPromoPriceCent) : 'null')
								// 20200421 END
								// 20200316 END
								.replace(/\*rPrice\*/g, p.rPrice ? changeFormatPrice(p.rPrice) : 'null')
								.replace(/\*rPromoPrice\*/g, p.rPromoPrice ? changeFormatPrice(p.rPromoPrice) : 'null')
								// 20200325 START 박지영 : price format 수정
								.replace(/\*rPriceCent\*/g, p.rPriceCent ? (p.rPriceCent) : 'null')
								.replace(/\*rPromoPriceCent\*/g, p.rPromoPriceCent ? (p.rPromoPriceCent) : 'null')
								// 20200325 END
								/* LGEUS-12083 : 20190826 modify */
								.replace(/\*rDiscountedPrice\*/g, p.rDiscountedPrice ? changeFormatPrice(p.rDiscountedPrice) : 'null')
								.replace(/\*rDiscountedPriceCent\*/g, p.rDiscountedPriceCent)
								/* //LGEUS-12083 : 20190826 modify */
								/* in-house review rating add */
								.replace(/\*sRating2\*/g, p.sRating2)
								.replace(/\*pCount\*/g, p.pCount)
								.replace(/\*ratingPercent\*/g, p.ratingPercent)
								/* // in-house review rating add */
								.replace(/\*siblingType\*/g, p.siblingType)
								.replace(/\*discountedRate\*/g, p.discountedRate)
								.replace(/\*discountMsg\*/g, p.discountMsg == null ? '' : p.discountMsg.replace(/&lt;/gi, '<').replace(/&gt;/gi, '>'))	// LGEIS-229 change how discounts are shown
								.replace(/\*retailerPricingText\*/g, p.retailerPricingText)
								.replace(/\*salesSuffixCode\*/g, (p.salesSuffixCode || ''))/* LGEGMC-455 20200717 add */
								.replace(/\*modelYear\*/g, (nvl(p.modelYear,'') || '')) /* LGEGMC-1279 : 2021.03.12 add */
								.replace(/\*buName1\*/g, (p.buName1 || ''))
								.replace(/\*buName2\*/g, (p.buName2 || ''))
								.replace(/\*buName3\*/g, (nvl(p.buName3,'') || ''))
								.replace(/\*bizType\*/g, (p.bizType || ''))
								.replace(/\*superCategoryName\*/g, p.superCategoryName)
								.replace(/\*categoryEngName\*/g, p.categoryEngName) // LGEGMC-1766 
								.replace(/\*priceValue\*/g, priceValue)/* LGEGMC-712 20201102 ADD */
								//PJTOBSB2E-3 Start
								.replace(/\*obsPreOrderStartDate\*/g, p.obsPreOrderStartDate)
								.replace(/\*obsPreOrderEndDate\*/g, p.obsPreOrderEndDate)
								//PJTOBSB2E-3 End
								.replace(/\*msrp\*/g, nvl(p.msrp,'0'))
								//PJTOBSEMI-4-num2 Start
								.replace(/\*emiMsg\*/g, p.obsEmiMsgFlag == 'Y' && p.emiMsg != null && p.emiMsg != '' ? p.emiMsg : '')
								//PJTOBSEMI-4-num2 End
								//LGEAU-378 START
								.replace(/\*afterPayInstallMent\*/g, p.obsEmiMsgFlag == 'Y' && p.emiMsg != null && p.emiMsg != '' && (p.afterPay <= 3000 && p.afterPay > 0) ? 'afterpay-installment" href="#modal-afterpay' : '" style="display:none;')
								//LGEAU-378 END
								.replace(/\*siblingCode\*/g, (nvl(p.siblingCode,'') || '')) // LGECN-214
								//LGEGMC-2202 START
								.replace(/\*wtbClass\*/g, (p.wtbExternalLinkUseFlag =="Y" && p.wtbExternalLinkUrl != null && p.wtbExternalLinkUrl != '' && p.wtbExternalLinkName != null && p.wtbExternalLinkName != '') ? 'in-buynow' : 'where-to-buy')
								//LGEGMC-2202
								//PJTMEMBERSHIP-8 START
								.replace(/\*obsMembershipLinkUrl\*/g, membershipFlagData.obsMembershipLinkUrl)
								.replace(/\*obsMembershipLinkTarget\*/g, membershipFlagData.obsMembershipLinkTarget)
								.replace(/\*membershipPriceValue\*/g, changeFormatFullPrice(p.rMembershipPrice, p.rMembershipPriceCent))
								.replace(/\*obsPartnerUrl\*/g, nvl(p.obsPartnerUrl, '')) //LGEEG-154
								.replace(/\*zipPayInstallMent\*/g, p.obsEmiMsgFlag == 'Y' && (p.afterPay > 0 && p.afterPay <= 10000) ? 'afterpay-installment" href="#modal-afterpay' : '" style="display:none;')/* LGEGMC-3167 add, OBSLGEAU-749 (5000 -> 10000) */
								.replace(/\*orZipPay\*/g, p.obsEmiMsgFlag == 'Y' && (p.afterPay > 0 && p.afterPay <= 3000) ? 'or ' : '')/* LGEGMC-3167 add */
								.replace(/\*calculatorSalesCode\*/g, (p.salesModelCode != null && p.salesModelCode != "") && (p.salesSuffixCode != null && p.salesSuffixCode != "") ? p.salesModelCode+"."+p.salesSuffixCode : p.salesModelCode) //LGEDE-672
								//PJTMEMBERSHIP-8 END
								// LGCOMPL-46 Start
								.replace(/\*obsSubscriptionLandingPageUrl\*/g, p.obsSubscriptionLandingPageUrl != null ? p.obsSubscriptionLandingPageUrl : '')
								.replace(/\*obsSubscriptionCtaLinkTarget\*/g, p.obsSubscriptionCtaLinkTarget != null ? p.obsSubscriptionCtaLinkTarget : '')
								.replace(/\*obsSubscriptionDisclaimer\*/g, p.obsSubscriptionDisclaimer != null ? p.obsSubscriptionDisclaimer : '')
								.replace(/\*obsConvertSubscriptionMonthlyCostArea\*/g, obsConvertSubscriptionMonthlyCostArea != '' ? obsConvertSubscriptionMonthlyCostArea : '')
								.replace(/\*obsSubscriptionMaxMonth\*/g, p.obsSubscriptionMaxMonth != null ? p.obsSubscriptionMaxMonth : '')
								// LGCOMPL-46 End
								//LGCOMITF-69 START
								.replace(/\*obsWelcomePrice\*/g, !!p.obsWelcomePriceUseFlag && 'Y' == p.obsWelcomePriceUseFlag ? changeFormatFullPrice(p.obsWelcomePrice, p.obsWelcomePriceCent) : '') //obsWelcomePriceUseFlag가 Y면 obsWelcomePrice, obsWelcomePriceDescription에 값이 존재함
								.replace(/\*obsWelcomePriceDescription\*/g, !!p.obsWelcomePriceUseFlag && 'Y' == p.obsWelcomePriceUseFlag ? p.obsWelcomePriceDescription : '')
								//LGCOMITF-69 END
								;
				//LGCOMMON-7821 Start
				var epsPictoFlag = p.epsPictoFlag;
				var includeChargerYn = p.includeChargerYn  == 'Y' ? '' : 'not-included';
				var minPower = p.minPower;
				var maxPower = p.maxPower;
				var epsPictogramHtml = "";
				
				if(epsPictoFlag == "Y"){
					epsPictogramHtml += '<div class="c-charging-device">';
					epsPictogramHtml += '<div class="c-info-charging">';
					epsPictogramHtml += '<i class="c-info-charging__icon ' + includeChargerYn + '" aria-hidden="true">';
					epsPictogramHtml += '<span class="c-info-charging__alt">';
					epsPictogramHtml += '<strong>' + minPower + ' - ' + maxPower + ' W</strong> USB PD';
					epsPictogramHtml += '</span>';
					epsPictogramHtml += '</i>';
					epsPictogramHtml += '<div class="c-info-charging__text">';
					epsPictogramHtml += '<strong>' + minPower + ' - ' + maxPower + ' W</strong> USB PD';
					epsPictogramHtml += '</div>';
					epsPictogramHtml += '</div>';
					epsPictogramHtml += '</div>';
				}
				template = template.replace(/\*epsPictogramArea\*/g, epsPictogramHtml);
				//LGCOMMON-7821 End	
							
				 //LGEGMC-383
				var pdfDownloadFile = $obj.find('input[name="pdfDownloadFile"]').val();
				var productFicheDownload = $obj.find('input[name="productFicheDownload"]').val();
				//LGESR-72
				var rsProductFicheDownload = $obj.find('input[name="rsProductFicheDownload"]').val();
				var rsUseFlag = p.rsUseFlag;
				if(rsUseFlag == "Y"){
					productFicheDownload = rsProductFicheDownload;
				}
				//LGEGMC-5315
				var docTypeCodeFlag = p.docTypeCodeFlag;
				if(docTypeCodeFlag == "N" || ((docTypeCodeFlag == "N" || docTypeCodeFlag == "O") && p.washTowerFlag=="Y")){
					var productFicheDownload = $obj.find('input[name="productNewFicheDownload"]').val();
				}else if(docTypeCodeFlag == "O"){
					var productFicheDownload = $obj.find('input[name="productOldFicheDownload"]').val();
				}
				//LGEGMC-5315
				
				//LGEGMC-3921
				var productFichehtml = '';
            	if(p.washTowerFlag=="Y"){
            		if( p.productFicheFileName !="" && p.productFicheOriginalName !="" && p.productFicheFileName != null && p.productFicheOriginalName !=null && p.productFicheDocId !=null && p.productFicheDocId !="" && p.secondProductFicheFileName !="" && p.secondProductFicheOriginalName !="" && p.secondProductFicheFileName != null && p.secondProductFicheOriginalName !=null && p.secondProductFicheDocId !=null && p.secondProductFicheDocId !=""){
            			productFichehtml += "<div class='energy-label-wrap'><a href='#' adobe-click='pdp-file-down-click' data-doc='"+ p.productFicheDocId +  "' data-file='" + p.productFicheFileName + "' data-original='" + p.productFicheOriginalName + "' data-category='' class='link-text text-tooltip' title='" + pdfDownloadFile + "'>"
            			+ "<span class='fiche type-product'>" + productFicheDownload + "</span>"
            			+ "</a>"+ "<div class='tooltip-link'><div class='tolltip-inner'>";
            			productFichehtml += "<a href='#' adobe-click='pdp-file-down-click' data-doc='"+p.productFicheDocId+ "' data-sku= '" + p.modelName + "' data-model-id= '" + p.modelId  +"'  data-file='"+p.productFicheFileName+"' data-original='"+p.productFicheOriginalName+"' data-category='' class='link-text link-text-uk' title='" + pdfDownloadFile +"("+p.productFicheproductLeve1Code+")"+ "'>"+p.productFicheproductLeve1Code+"</a>";
						productFichehtml += "<a href='#' adobe-click='pdp-file-down-click' data-doc='" + p.secondProductFicheDocId + "' data-file='" + p.secondProductFicheFileName + "' data-original='" + p.secondProductFicheOriginalName + "'  data-category='' class='link-text link-text-eu' title='" + pdfDownloadFile +"("+p.secondProductFicheproductLeve1Code+")"+ "'>"+p.secondProductFicheproductLeve1Code+"</a>"
						+ "</div></div></div>";
            		}else{
            			if(p.productFicheFileName !="" && p.productFicheOriginalName !="" && p.productFicheFileName != null && p.productFicheOriginalName !=null && p.productFicheDocId !=null && p.productFicheDocId !=""  ){
            				productFichehtml += "<a href='#' adobe-click='pdp-file-down-click' data-doc='"+ p.productFicheDocId + "' data-file='" + p.productFicheFileName + "' data-original='" + p.productFicheOriginalName + "' data-category='' class='link-text' title='" + pdfDownloadFile +"("+p.productFicheproductLeve1Code+")"+ "'>"
                 			+ "<span class='fiche type-product'>" + productFicheDownload + "</span>"
                 			+ "</a>";
	                	}
            		}
            	}else{
            		if(p.productFicheFileName !="" && p.productFicheOriginalName !="" && p.productFicheFileName != null && p.productFicheOriginalName !=null && p.productFicheDocId !=null && p.productFicheDocId !=""  ){
        				productFichehtml += "<a href='#' adobe-click='pdp-file-down-click' data-doc='"+ p.productFicheDocId + "' data-file='" + p.productFicheFileName + "' data-original='" + p.productFicheOriginalName + "' data-category='' class='link-text' title='" + pdfDownloadFile +"("+p.productFicheproductLeve1Code+")"+ "'>"
             			+ "<span class='fiche type-product'>" + productFicheDownload + "</span>"
             			+ "</a>";
                	}
            	}
            	//LGEGMC-3921
				/*LGEGMC-1035 start*/
				if($('html').attr('data-countrycode') == 'uk'){
					if(p.energyLabel != "" && p.energyLabel != "N" && p.energyLabel != null && p.energyLabelDocId !=null && p.energyLabelDocId !="" && p.energyLabelFileName != null && p.energyLabelFileName != "" && p.energyLabelOriginalName !=null
							&& p.energyLabelOriginalName !="" && p.energyLabelImageAddr !=null && p.energyLabelImageAddr !="" && p.energyLabelName !=null && p.energyLabelName !="" && p.fEnergyLabelFileName!= null && p.fEnergyLabelDocId!= null && p.fEnergyLabelDocId!= ""&&p.fEnergyLabelFileName!='' && p.fEnergyLabelOriginalName !=null&& p.fEnergyLabelOriginalName !=''){
						var energyLabelhtml = "<div class='energy-label-wrap'><a href='#' class='label-link'><span class='label'><img src='"+ p.energyLabelImageAddr +"' alt='"+ p.energyLabelName +"'></span></a>"
						var energyLabelImagehtml = "<div class='tooltip-link'><div class='tolltip-inner'>";
						if(p.fEnergyLabelFileName!= null && p.fEnergyLabelDocId!= null && p.fEnergyLabelDocId!= ""&&p.fEnergyLabelFileName!='' && p.fEnergyLabelOriginalName !=null&& p.fEnergyLabelOriginalName !=''){
							energyLabelImagehtml += "<a href='#' class='link-text link-text-uk' adobe-click='pdp-file-down-click' data-doc='" + p.fEnergyLabelDocId + "' data-file='" + p.fEnergyLabelFileName + "' data-original='" + p.fEnergyLabelOriginalName + "'  data-category='' title='" + pdfDownloadFile +"("+p.fenergyLabelproductLeve1Code+")"+ "'>"+$("#pdfDownloadFileUk").val()+"</a>";
						}
					energyLabelImagehtml += "<a href='#' class='link-text link-text-eu' adobe-click='pdp-file-down-click' data-doc='" + p.energyLabelDocId + "' data-file='" + p.energyLabelFileName + "' data-original='" + p.energyLabelOriginalName + "'  data-category='' title='" + pdfDownloadFile +"("+p.energyLabelproductLeve1Code+")"+ "'>"+$("#pdfDownloadFileEu").val()+"</a></div></div></div>"
					}else{
						var energyLabelhtml = "<a href='#' adobe-click='pdp-file-down-click' data-doc='" + p.energyLabelDocId + "' data-file='" + p.energyLabelFileName + "' data-original='" + p.energyLabelOriginalName + "' class='link-text' data-category='' title='" + pdfDownloadFile +"("+p.energyLabelproductLeve1Code+")"+ "'>"
						+ "<span class='label type-none'>";
						var energyLabelImagehtml = "<img src='"+ p.energyLabelImageAddr +"' alt='"+ p.energyLabelName +"'></span></a>";
					}
				}else{
					var energyLabelhtml = "<a href='#' adobe-click='pdp-file-down-click' data-doc='" + p.energyLabelDocId + "' data-file='" + p.energyLabelFileName + "' data-original='" + p.energyLabelOriginalName + "' class='link-text' data-category='' title='" + pdfDownloadFile +"("+p.energyLabelproductLeve1Code+")"+ "'>"
					+ "<span class='label type-none'>";
					var energyLabelImagehtml = "<img src='"+ p.energyLabelImageAddr +"' alt='"+ p.energyLabelName +"'></span></a>";
				}
				/*LGEGMC-1035 end*/
				//LGEGMC-3921
			if(p.washTowerFlag=="Y"){
				if($('html').attr('data-countrycode') == 'uk'){
					if(p.secondEnergyLabel != "" && p.secondEnergyLabel != "N" && p.secondEnergyLabel != null && p.secondEnergyLabelDocId !=null && p.secondEnergyLabelDocId !="" && p.secondEnergyLabelFileName != null && p.secondEnergyLabelFileName != "" && p.secondEnergyLabelOriginalName !=null
							&& p.secondEnergyLabelOriginalName !="" && p.secondEnergyLabelImageAddr !=null && p.secondEnergyLabelImageAddr !="" && p.secondEnergyLabelName !=null && p.secondEnergyLabelName !="" && p.secondFEnergyLabelFileName!= null && p.secondFEnergyLabelDocId!= null && p.secondFEnergyLabelDocId!= ""&&p.secondFEnergyLabelFileName!='' && p.secondFEnergyLabelOriginalName !=null&& p.secondFEnergyLabelOriginalName !=''){
						var secondEnergyLabelhtml = "<div class='energy-label-wrap'><a href='#' class='label-link'><span class='label'><img src='"+ p.secondEnergyLabelImageAddr +"' alt='"+ p.secondEnergyLabelName +"'></span></a>"
						var secondEnergyLabelImagehtml = "<div class='tooltip-link'><div class='tolltip-inner'>";
						secondEnergyLabelImagehtml += "<a href='#' class='link-text link-text-uk' adobe-click='pdp-file-down-click' data-doc='" + p.secondFEnergyLabelDocId + "' data-file='" + p.secondFEnergyLabelFileName + "' data-original='" + p.secondFEnergyLabelOriginalName + "'  data-category='' title='" + pdfDownloadFile +"("+p.secondFEnergyLabelproductLeve1Code+")"+ "'>"+$("#pdfDownloadFileUk").val()+"</a>";
						secondEnergyLabelImagehtml += "<a href='#' class='link-text link-text-eu' adobe-click='pdp-file-down-click' data-doc='" + p.secondEnergyLabelDocId + "' data-file='" + p.secondEnergyLabelFileName + "' data-original='" + p.secondEnergyLabelOriginalName + "'  data-category='' title='" + pdfDownloadFile +"("+p.secondEnergyLabelproductLeve1Code+")"+ "'>"+$("#pdfDownloadFileEu").val()+"</a></div></div></div>"
					}else{
						var secondEnergyLabelhtml = "<a href='#' adobe-click='pdp-file-down-click' data-doc='" + p.secondEenergyLabelDocId + "' data-file='" + p.secondEnergyLabelFileName + "' data-original='" + p.secondEnergyLabelOriginalName + "' class='link-text' data-category='' title='" + pdfDownloadFile +"("+p.secondEnergyLabelproductLeve1Code+")"+ "'>"
						+ "<span class='label type-none'>";
						var secondEnergyLabelImagehtml = "<img src='"+ p.secondEnergyLabelImageAddr +"' alt='"+ p.secondEnergyLabelName +"'></span></a>";
					}
				}else{
					var secondEnergyLabelhtml = "<a href='#' adobe-click='pdp-file-down-click' data-doc='" + p.secondEenergyLabelDocId + "' data-file='" + p.secondEnergyLabelFileName + "' data-original='" + p.secondEnergyLabelOriginalName + "' class='link-text' data-category='' title='" + pdfDownloadFile +"("+p.secondEnergyLabelproductLeve1Code+")"+ "'>"
					+ "<span class='label type-none'>";
					var secondEnergyLabelImagehtml = "<img src='"+ p.secondEnergyLabelImageAddr +"' alt='"+ p.secondEnergyLabelName +"'></span></a>";
				}
			}	
			//LGEGMC-3921
			//LGEGMC-5315
			if(docTypeCodeFlag == "N" || docTypeCodeFlag == "O"){
				if(p.productFicheFileName !="" && p.productFicheOriginalName !="" && p.productFicheFileName != null && p.productFicheOriginalName !=null && p.productFicheDocId !=null && p.productFicheDocId != ""){
					template = template.replace(/\*productFileName2\*/g,productFichehtml);
					template = template.replace(/\*productFileName\*/g,"");
					ynEnergyLabel = "Y";
				} else{
					template = template.replace(/\*productFileName2\*/g,"");
					template = template.replace(/\*productFileName\*/g,"");
				}
			}else{
				if(p.productFicheFileName !="" && p.productFicheOriginalName !="" && p.productFicheFileName != null && p.productFicheOriginalName !=null && p.productFicheDocId !=null && p.productFicheDocId != ""){
					template = template.replace(/\*productFileName\*/g,productFichehtml);
					template = template.replace(/\*productFileName2\*/g,"");
					ynEnergyLabel = "Y";
				} else{
					template = template.replace(/\*productFileName\*/g,"");
					template = template.replace(/\*productFileName2\*/g,"");
				}
			}
			//LGEGMC-5315
			if(p.energyLabel != "" && p.energyLabel != "N" && p.energyLabel != null && p.energyLabelDocId !=null && p.energyLabelDocId !="" && p.energyLabelFileName != null && p.energyLabelFileName != "" && p.energyLabelOriginalName !=null
					&& p.energyLabelOriginalName !="" && p.energyLabelImageAddr !=null && p.energyLabelImageAddr !="" && p.energyLabelName !=null && p.energyLabelName !=""){
				template = template.replace(/\*energyLabel\*/g,energyLabelhtml);
				template = template.replace(/\*energyLabelImage\*/g,energyLabelImagehtml);
					ynEnergyLabel = "Y";
			} else{
				template = template.replace(/\*energyLabelImage\*/g, "");
				template = template.replace(/\*energyLabel\*/g,"");
			} 
			
			if(p.secondEnergyLabel != "" && p.secondEnergyLabel != "N" && p.secondEnergyLabel != null && p.secondEnergyLabelDocId !=null && p.secondEnergyLabelDocId !="" && p.secondEnergyLabelFileName != null && p.secondEnergyLabelFileName != "" && p.secondEnergyLabelOriginalName !=null
					&& p.secondEnergyLabelOriginalName !="" && p.secondEnergyLabelImageAddr !=null && p.secondEnergyLabelImageAddr !="" && p.secondEnergyLabelName !=null && p.secondEnergyLabelName !=""){
			template = template.replace(/\*secondEnergyLabel\*/g,secondEnergyLabelhtml);
			template = template.replace(/\*secondEnergyLabelImage\*/g,secondEnergyLabelImagehtml);
				ynEnergyLabel = "Y";
		    } else{
				template = template.replace(/\*secondEnergyLabel\*/g, "");
				template = template.replace(/\*secondEnergyLabelImage\*/g,"");
			}              
			//LGEFR-640
			var repairabilityArea = $(template).find('.repairability-index');
			var repairabilityMsg = repairabilityArea.attr('data-repairability-msg');
			var targetBlankMsg = repairabilityArea.attr('data-target-blank-msg');
			
			if(!!p.labelRepairMap && p.labelRepairMap.length > 0){		/* LGETW-651 !!p.labelRepairMap add */
				var repairMapData = p.labelRepairMap[0];
				var repairAbilityHtml  = "<div class='score'>";
				    repairAbilityHtml  += "<span class='txt'>"+repairabilityMsg+"</span>";
				if(repairMapData.linkUrl != 'null' && repairMapData.linkUrl != ''){
					repairAbilityHtml  += "<a href='"+repairMapData.linkUrl+"'";
					if(repairMapData.linkOpt =='S'){
						repairAbilityHtml  += " target='_self'";
					}else if(repairMapData.linkOpt =='B'){
						repairAbilityHtml  += " target='_blank'";
					}
					repairAbilityHtml  += " title='"+targetBlankMsg+"' class='link-pdf'>";
				}else{
                    repairAbilityHtml += "<span class='link-pdf'>";
                }
				if(repairMapData.imagePathAddr != 'null' && repairMapData.imagePathAddr != ''){
					repairAbilityHtml  += "<img src='"+repairMapData.imagePathAddr+"' alt='"+repairMapData.altText+"' area-hidden='true' />";
				}
				if(repairMapData.linkUrl != 'null' && repairMapData.linkUrl != ''){
					repairAbilityHtml  += "</a></div>";
				}else{
                    repairAbilityHtml  += "</span></div>";
                }
				template = template.replace(/\*repairabilityIndex\*/g, repairAbilityHtml);
			}else{
				template = template.replace(/\*repairabilityIndex\*/g, "");
			}
			//LGEFR-640	
				var $template = $(template),
					$keyBlocks = $template.find('*[data-key]');

				for (var i1 = 0; i1 < $keyBlocks.length; i1++) {
					var $currentKeyBlock = $keyBlocks.eq(i1),
						key = $currentKeyBlock.get(0).getAttribute('data-key'),
						val = p[key];
					if(!val || (val == null || val == "N")) {
						$currentKeyBlock.remove();
					}
				}
				
				//PJTPDR-1 START
				var pdrCompareUseFlag = p.pdrCompareUseFlag;
				if(pdrCompareUseFlag == 'N'){
					$template.find('.wishlist-compare').hide();
				}
				//PJTPDR-1 END
				
				// in-house reivew rating star - ie fix
				$template.find('.carmine-star').css({
					width: p.reviewRatingPercent+"%"
				});

				// LGEIS-800 Start
				// for template ajax tab (exciting product tab)
				var $productTag = $template.find('.tag-content'),
					$productTag1 = $productTag.find('[data-key="productTag1"]'),
					$productTag2 = $productTag.find('[data-key="productTag2"]'),
					obsTagfilter1 = p.limitSaleUseFlag == 'Y' && p.obsLimitSale == 'Y' ? true : false,
					obsTagfilter2 = p.obsPreOrderFlag == 'Y' || p.obsPreOrderRSAFlag == 'Y' ? true : false;
				
				//LGCOMCH-108 START
				if(p.obsComTagShowFlag == 'Y') {
					if(!!p.productTag1 && p.productTag1 != '' && p.productTag1Type == 'OBS') {
						$productTag1.closest('.d-none').addClass('tag-imp');
					}
					if(!!p.productTag2 && p.productTag2 != '' && p.productTag2Type == 'OBS') {
						$productTag2.closest('.d-none').addClass('tag-imp');
					}
                } else { //기존 로직
                	if($productTag1.length && (obsTagfilter1 || obsTagfilter2) )
						$productTag1.closest('.d-none').addClass('tag-imp'); // productTag1
                	if($productTag2.length && obsTagfilter1)
						$productTag2.closest('.d-none').addClass('tag-imp'); // productTag2
                }
				//LGCOMCH-108 END
				
				// vip 여부에 따른 tag display + default display 기능 코드는 common.js 에 통합적으로 존재하지만,
				// common.js 로드 갭타임 등의 이유로 GPC00003의 default display는 해당 JS에서 default 코드로 관리하도록 코딩함
				// => 여러 PLP/PDP 컴포넌트의 vip tag display 기능이 common.js로 통합된 이유는 logged, is-vip 여부를 common.js 로드 시점에서만 확인할 수 있기 때문
				// common.js 에서 GPC00003의 display 여부는 logged, is-vip 관련 코드에만 추가함
				$productTag.find('[data-user-type=""], [data-user-type=ALL], [data-user-type=NON_VIP]').removeClass('d-none');
				// LGEIS-800 End

				// LGEVN-819 Start
				switch ($template.find(".tag-content p").length) {
				case 1:
					if(p.productTag1 != null && p.productTag1ColorFlag == "Y"){
						$($template.find(".tag-content p")[0]).addClass("tag-gold");
					}
					if(p.productTag2 != null && p.productTag2ColorFlag == "Y"){
						$($template.find(".tag-content p")[0]).addClass("tag-gold");
					}
					break;
					
				case 2:
					if(p.productTag1 != null && p.productTag1ColorFlag == "Y"){
						$($template.find(".tag-content p")[0]).addClass("tag-gold");
					}
					if(p.productTag2 != null && p.productTag2ColorFlag == "Y"){
						$($template.find(".tag-content p")[1]).addClass("tag-gold");
					}
					break;
					
				default:
					break;
				}
				// LGEVN-819 End
				
				// price setting
				var $prices = $template.find('.price-area');
				if(p.modelStatusCode=='DISCONTINUED') {
					$prices.filter('.rPrice').html("");
					$prices.filter('.rPromoPrice').remove();
					$prices.filter('.retailer').remove();
				} else if(p.retailerPricingFlag=="Y") {
					$prices.filter('.rPrice').remove();
					$prices.filter('.rPromoPrice').remove();
				}else {
					if((p.rPromoPrice && p.rPromoPrice != null) && (p.rPrice && p.rPrice != null)) {
						$prices.filter('.rPrice').remove();
						$prices.filter('.retailer').remove();
					}else if(p.rPrice && p.rPrice != null){
						$prices.filter('.rPromoPrice').remove();
						$prices.filter('.retailer').remove();
					}else {
						$prices.filter('.rPrice').html("");
						$prices.filter('.rPromoPrice').remove();
						$prices.filter('.retailer').remove();
					}
				}
				// PJTOBS-32 Start
				if(ISVIP) $prices.addClass('vip-price-area');
				//PJTLIMITQTY_EXTEND
				var limitSaleConditionFlag =  p.vipPriceFlag == 'N' && p.obsLimitSale == 'Y' && p.limitSaleUseFlag == 'Y' ? 'Y' : 'N';
				if(p.vipPriceFlag == 'Y') {
					var priceOrg = changeFormatFullPrice(p.rPrice, p.rPriceCent);
					var pricePromo = changeFormatFullPrice(p.rPromoPrice, p.rPromoPriceCent);
					var legal = p.discountMsg == null ? '' : p.discountMsg.replace(/&lt;/gi, '<').replace(/&gt;/gi, '>');		// LGEIS-229 change how discounts are shown
					var vipPriceText = productMessages.vipPriceMessage;
					//LGEMS-432 Start
					if(COUNTRY_CODE.toLowerCase() == 'mx' && 'B2B' == $('.navigation').attr('data-obs-group')){
						vipPriceText = productMessages.b2bVipPriceMessage;
					}
					//LGEMS-432 End
					var previousPriceText = productMessages.previousPriceText;
					// PJTOBSEMI-4-num2 Start
					var emiMsgText = p.obsEmiMsgFlag == 'Y' && p.emiMsg != null && p.emiMsg != '' ? p.emiMsg : '';

					setVipPrice($prices, priceOrg, pricePromo, legal, vipPriceText, previousPriceText, p.modelId + '/' + 'GPC0003.js',emiMsgText,p.afterPay, limitSaleConditionFlag, p.limitSaleTitle); // LGCOMMON-54
					// PJTOBSEMI-4-num2 End
				}else{
					//LGCOMIS-121
					var countryCode = COUNTRY_CODE.toLowerCase();
					//PJTLIMITQTY_EXTEND
					if(p.obsLimitSale == 'Y' && p.limitSaleUseFlag == 'Y'){
						//LGCOMIS-121
						if(countryCode == 'it'){
							$template.find('.price-vip').text('');
						}else{
							$template.find('.price-vip').text(p.limitSaleTitle);
						}
					}else{
						$template.find('.price-vip').text('');
					}
					//PJTLIMITQTY_EXTEND
				}
				// PJTOBS-32 End
				// LGCOMMONPL-218  start
				if ( p.obsLowestPriceFlag == 'Y' && !!p.rPromoPrice ) {
					if ( $template.find('.lowest-price-mark').length ) $template.find('.lowest-price-mark').removeClass('d-none');
					if ( $template.find('.lowest-price').length ) $template.find('.lowest-price').removeClass('d-none'); 
				} else {
					if ( $template.find('.lowest-price-mark').length ) $template.find('.lowest-price-mark').remove();
					if ( $template.find('.lowest-price').length ) $template.find('.lowest-price').remove();
				}
				// LGCOMMONPL-218  end
				
				//LGCOMITF-69 START
				if(!!p.obsWelcomePriceUseFlag && 'Y' == p.obsWelcomePriceUseFlag){
					$template.find('.coupon-price').removeClass('d-none');
				}else{
					$template.find('.coupon-price').html('');
				}
				//LGCOMITF-69 END
				
				// PJTMEMBERSHIP-8 Start
				var $membershipPrices = $template.find('.member-text');
				if(p.membershipDisplayFlag=='Y') {
					$template.find('.price-wt-box').addClass('has-member');
					/* LGCOMPA-44 start */
					if(  SIGN_IN_STATUS == 'N'
					  && !!membershipFlagData.guestPriceMessageUseFlag
					  &&   membershipFlagData.guestPriceMessageUseFlag == 'Y'
					  && !!membershipFlagData.guestPriceMessage){
						$membershipPrices.html('').append(membershipFlagData.guestPriceMessage);
					}
					/* LGCOMPA-44 end */
				}else{
					$membershipPrices.filter('.member-text').remove();
				}
				// PJTMEMBERSHIP-8 End

				// 20200427 START 박지영 - cac와 cac_en에서 review가 0일때 별점 삭제
				// cac, cac_en (in house review)
				var cc = COUNTRY_CODE.toLowerCase();
				if(cc=='cac' || cc=='cac_en') {
					if(p.sRating2==0 && p.sRating2==0 && p.ratingPercent==0) {
						$template.find(".rating.inhouse-review").remove();
					}
				}
				// 20200427 END

				// rolling image
				if(p.modelRollingImgList && p.modelRollingImgList != null) {
					$template.find('.visual img.pc').addClass('js-thumbnail-loop').attr('data-img-list', p.modelRollingImgList);
				}

				// PJTOBS 20200703 Start
				//PJTLIMITQTY_EXTEND
				var $stockArea = $template.find('.stock-area');
				if((!p.reStockAlertFlag || p.reStockAlertFlag!='Y') && $stockArea.length>0) {
					if( p.limitSaleUseFlag == 'Y' && p.obsLimitSale == 'Y'){
						if(p.obsInventoryFlag == 'Y'){
							$stockArea.removeClass('out-of-stock').empty();
						}else{
							$stockArea.find('.text').text(productMessages.limitSaleSoldOutText);
						}
					}else{
						$stockArea.removeClass('out-of-stock').empty();
					}
				}else{
					if(p.limitSaleUseFlag == 'Y' && p.obsLimitSale == 'Y'){
						$stockArea.find('.text').text(productMessages.limitSaleSoldOutText);
					}
				}
				// PJTOBS 20200703 End

				// buttons
				// PJTOBS 20200703 Start
				if((!p.reStockAlertFlag || p.reStockAlertFlag!='Y') && $template.find('.button a.re-stock-alert').length>0) {
					$template.find('.button a.re-stock-alert').remove();
				}
				// PJTOBS 20200703 End
				// LGEVN-80
				var obsBuynowFlag = $obj.find('input[name="obsBuynowFlag"]').val();
				if(p.obsPreOrderFlag == 'Y'){ //PJTOBS/2020/PJTOBSB2E-6 GILS
					if(obsBuynowFlag == 'Y'){
						$template.find('.button a.add-to-cart').data('model-id', p.modelId).attr('href', p.modelUrlPath).text(productMessages.preOrderBtnNm).removeAttr('target, title')
						.addClass('pre-order').attr('data-obs-pre-order-start-date',p.obsPreOrderStartDate).attr('data-obs-pre-order-end-date',p.obsPreOrderEndDate)
						;
					}else{
						$template.find('.button a.add-to-cart').data('model-id', p.modelId).attr('href', '#').text(productMessages.preOrderBtnNm).attr('role', 'button').removeAttr('target, title')
						.addClass('pre-order').attr('data-obs-pre-order-start-date',p.obsPreOrderStartDate).attr('data-obs-pre-order-end-date',p.obsPreOrderEndDate)
						;						
					}
				}else if(p.addToCartFlag!="N") {
					if(p.addToCartFlag == 'Y') {
						// 통합 OBS
						if(obsBuynowFlag == 'Y'){
							// 통합 OBS
							var buynow = $obj.find('input[name="buynow"]').val();
							$template.find('.button a.add-to-cart').data('model-id', p.modelId).attr('href', p.modelUrlPath).text(buynow).removeAttr('target, title');
						}else{
							// 통합 OBS
							$template.find('.button a.add-to-cart').data('model-id', p.modelId).attr('href', '#').text(productMessages.addToCartBtnNm).attr('role', 'button').removeAttr('target, title');
						}
					} else if(p.addToCartFlag == 'S') {
						// Standalone OBS
						$template.find('.button a.add-to-cart').data('model-id', p.modelId).attr('href', '#').text(productMessages.addToCartBtnNm).attr('role', 'button').removeAttr('target, title');
					}
				} else if(p.buyNowFlag=="Y" || p.buyNowFlag=="L") {
					if(p.ecommerceTarget == '_blank') {
						$template.find('.button a.add-to-cart').addClass('in-buynow').data('model-id', p.modelId).attr('href', p.buyNowUrl).text(productMessages.buyNowBtnNm).removeAttr('role').attr('target', '_blank').attr('title', productMessages.btnNewLinkTitle);//LGEGMC-1567
					}else {
						$template.find('.button a.add-to-cart').addClass('in-buynow').data('model-id', p.modelId).attr('href', p.buyNowUrl).text(productMessages.buyNowBtnNm).removeAttr('role').removeAttr('target, title');//LGEGMC-1567
					}
				// 20200506 START 박지영 - flag 명 변경
				} else if (p.resellerBtnFlag=="Y") {
				// 20200506 END
					$template.find('.button a.add-to-cart').addClass('active').data('model-id', p.modelId).attr('href', p.resellerLinkUrl).text(productMessages.resellerBtnNm).removeAttr('role').attr('target', '_blank').attr('title', productMessages.btnNewLinkTitle);
				} else if (p.productSupportFlag=="Y") {
					$template.find('.button a.add-to-cart').addClass('active').data('model-id', p.modelId).attr('href', p.productSupportUrl).text(productMessages.productSupportBtnNm).removeAttr('role').removeAttr('target, title');
				} else {
					$template.find('.button a.add-to-cart').remove();
				}
				// WTB btn
				if(p.whereToBuyFlag=="Y" && p.whereToBuyUrl != null && p.whereToBuyUrl != '') {
					// go to pdp page
					$template.find('.button a.where-to-buy').attr('href', p.whereToBuyUrl).text(productMessages.whereToBuyBtnNm);
					$template.find('.button a.where-to-buy').removeAttr('target, title');
				// 20200410 START 박지영 - wtb external link 변경
				} else if(p.wtbExternalLinkUseFlag=="Y" && p.wtbExternalLinkUrl != null && p.wtbExternalLinkUrl != '' && p.wtbExternalLinkName != null && p.wtbExternalLinkName != '') {
					// go to external link
					//LGEGMC-2202 START
					$template.find('.button a.in-buynow').attr('href', p.wtbExternalLinkUrl).text(p.wtbExternalLinkName).attr('data-link-name', 'buy_now').removeAttr('data-sc-item');
					if(p.wtbExternalLinkSelfFlag == 'Y') {
						$template.find('.button a.in-buynow').removeAttr('target, title');
					} else {
						$template.find('.button a.in-buynow').attr('target', '_blank').attr('title', productMessages.btnNewLinkTitle);
					}
					//LGEGMC-2202 END
				// 20200410 END
				} else {
					$template.find('.button a.where-to-buy').remove();
				}
				
				// LGCOMPL-46 Start 
				if(p.obsSubscriptionEnableFlag != null && p.obsSubscriptionEnableFlag == 'Y') {
					$template.find('.button a.subscribe-box').removeClass('d-none');
				}
				// LGCOMPL-46 End

				//LGEEG-154
				if(p.buyNowUnionStoreBtnFlag !="Y"){
					$template.find('.button a.buyNowUnionBtn').remove();
				}
				// Find a dealer btn
				if(p.findTheDealerFlag=="Y" && p.findTheDealerUrl != null && p.findTheDealerUrl != '') {
					$template.find('.button a.find-a-dealer').attr('href', p.findTheDealerUrl).text(productMessages.findTheDealerBtnNm);
				} else {
					$template.find('.button a.find-a-dealer').remove();
				}
				// inquiry to buy btn
				if(p.inquiryToBuyFlag=="Y" && p.inquiryToBuyUrl != null && p.inquiryToBuyUrl != '') {
					$template.find('.button a.inquiry-to-buy').attr('href', p.inquiryToBuyUrl).text(productMessages.inquiryToBuyBtnNm);
				} else {
					$template.find('.button a.inquiry-to-buy').remove();
				}

				// 20200511 START 박지영 - 3번, 4번 컴포넌트 버튼 색상 변경
				// button - change color (Changing button color to JavaScript is only the GPC0003 and GPC0004 components.)
				if((OBS_LOGIN_FLAG=='N' || OBS_LOGIN_FLAG == 'T') && p.buyNowUseFlag == 'N' && p.resellerBtnFlag == 'N' && p.productSupportFlag == 'N') {
					// change color (No red button) 
					if($template.find('.button a.add-to-cart').length>0) $template.find('.button a.add-to-cart').removeClass('btn-primary').addClass('btn-outline-secondary');
					if($template.find('.button a.where-to-buy').length>0) $template.find('.button a.where-to-buy').addClass('btn-primary').removeClass('btn-outline-secondary');
				}
				// 20200511 END

				$template.find('template').remove();
				template = $template.get(0).outerHTML;
				html += template;
			}

			return html;
		},
		addAriaDescribedby: function(){
			var _this = GPC0003;
			var waNumber = 0;
			$(_this.el).find('.unit-list .item').each(function() {
				var $target;
				if($(this).find('.model-name a') && !$(this).find('.model-name a').is(':empty')) {
					$target = $(this).find('.model-name a');
				}
				if($target) {
					$target.attr('id', 'waGPC0003_'+waNumber);
					$(this).find('a.btn').attr('aria-describedby', 'waGPC0003_'+waNumber);
					$(this).find('a.js-compare').attr('aria-describedby', 'waGPC0003_'+waNumber).attr('role', 'button');
					waNumber++;
				}
			});
		},
		// 20200406 START 이상현 - tab ui의 관계 정보 추가
		addAriaRelationship : function(tg){
			if($(tg).find('.js-tab').length > 0){
				$(tg).find('.js-tab').find('[role="tab"]').each(function(idx) {
					$(this).attr('id', 'tabLabel_' + (idx+1));
					$(tg).find('[role="tabpanel"]').eq(idx).attr('aria-labelledby', 'tabLabel_' + (idx+1));
				});
			}
		},
		// 20200406 END
		addEvent: function(){
			var _this = GPC0003;
			$(_this.el).on({
				submit: function(e){
					e.preventDefault();
					//console.log('!!');
					var isExpander = $(e.delegateTarget).data('focus') == true;
					var url = e.currentTarget.action,
						param = encodeURI(xssfilter($(e.currentTarget).serialize()));
					var $target = $(e.currentTarget).siblings('.list-contents-wrap');
					var $form = $(e.currentTarget);
					ajax.noCacheCall(url, param, 'json', function(d){
						var data, html;
						if(d && d.data) data = d.data instanceof Array ? d.data[0] : d.data;

						if(d.status == "success" && (data && (data.productList && data.productList.length > 0))) {
							//PJTMEMBERSHIP-8 Start
							var membershipFlagData = {};
							membershipFlagData.obsMembershipLinkUseFlag = data.obsMembershipLinkUseFlag;
							membershipFlagData.obsMembershipLinkUrl = data.obsMembershipLinkUrl;
							membershipFlagData.obsMembershipLinkTarget = data.obsMembershipLinkTarget;
							//PJTMEMBERSHIP-8 End
							/* LGCOMPA-44 start */
							if(!!data.guestPriceMessageUseFlag && data.guestPriceMessageUseFlag == 'Y'){
								membershipFlagData.guestPriceMessage = data.guestPriceMessage;
								membershipFlagData.guestPriceMessageUseFlag = data.guestPriceMessageUseFlag;
							}
							/* LGCOMPA-44 end */
							// making markup
							html = GPC0003.createProductItem(data.productList, data.productMessages, membershipFlagData); //PJTMEMBERSHIP-8 membershipFlagData추가

							if($target.find('.unit-list').is('.slick-initialized')) {
								$target.find('.unit-list').slick('unslick');
							}
							
							$target.find('.unit-list').html(html);
							/* LGETW-651 start */
							if(!!data.obsInstallmentPriceDisableLink && data.obsInstallmentPriceDisableLink == 'Y'){
								$target.find('.price-installment').addClass('no-link').attr('tabindex','-1');
							}
							/* LGETW-651 end */
							if($target.hasClass('initialized')) {
								$('html, body').animate({
									scrollTop: $target.closest('.component').offset().top
								});
							}
							$target.find('.unit-list').slick(GPC0003.opt);
							$target.addClass('initialized');
							_this.addAriaDescribedby();
							bindImgError();
							runBVStaticPLP($target);
							if(typeof renderListingInlineRatingsRU != 'undefined') renderListingInlineRatingsRU(getProductsNameRU());

							// over, out, focus, blur 이벤트 - ajax 호출시

							// interaction handler
							$target.find(".item a.visual").off("mouseenter").on("mouseenter", function(){ // WA-GPC0003-09
								$target.find(".item").removeClass("on-focus");
								$(this).closest(".item").addClass("on-focus");
							});
							$target.find(".item").off("mouseleave").on("mouseleave", function(e){ // WA-GPC0003-09
								$(this).removeClass("on-focus");
							});
							// WA-GPC0003-09
							setTimeout(function() { // fix bv script
								$target.find('.item').each(function() {
									var $blurlink = $(this).find('.model-name a');
									if($(this).find('a.bv_main_container').length>0) $blurlink = $(this).find('a.bv_main_container');
									if($(this).find('.rating.inhouse-review').length>0) $blurlink = $(this).find('.rating.inhouse-review > a');
									if($(this).find('.rating.loaded').length>0) $blurlink = $(this).find('.rating.loaded a');
									$blurlink.off('blur.showbtn').on('blur.showbtn', function(e) {
										$(this).closest(".item").addClass("on-focus");
										$(this).closest(".item").siblings().removeClass('on-focus');
										// 20200401 START 박지영 : 모바일에서 불필요한 스크립트 동작하지 않도록 수정 
										if($(this).closest(".item").find('.button a').length>0) {
											if($(window).width() >= 768) $(this).closest(".item").find('.button a').eq(0).focus();
										} else if($(this).closest(".item").find('.wishlist-compare a').length>0) {
											if($(window).width() >= 768) $(this).closest(".item").find('.wishlist-compare a').eq(0).focus();
										}
										// 20200401 END
									});
								});
							}, 1000);
							$target.find(".item a.visual").off("focus.showbtn").on("focus.showbtn", function(){
								//$(this).removeClass("on-focus");
								$target.find(".item").removeClass("on-focus");
							});

							// -- over, out, focus, blur 이벤트 - ajax 호출시
							
							/* LGECN-214 Start */
							if(lgCggroupName == 'home' && COUNTRY_CODE.toLowerCase() == 'cn' && typeof _hmt != 'undefined'){
								$obj.find('button').off('click.baidu').on('click.baidu', function(){
									if($(this).hasClass('slick-prev')){
										console.log('[Baidu trackEvent] [Homepage_Product Impression] [Click Previous]', ', $lg_cggroup : '+ $lg_cggroup);
										_hmt.push(['_trackEvent',$lg_cggroup,'Click Previous','Homepage_Product Impression']);
									}else if($(this).hasClass('slick-next')){
										console.log('[Baidu trackEvent] [Homepage_Product Impression] [Click Next]', ', $lg_cggroup : '+ $lg_cggroup);
										_hmt.push(['_trackEvent',$lg_cggroup,'Click Next','Homepage_Product Impression']);
									}
								});
							}
							/* LGECN-214 End */

							$form.trigger('ajaxLoadEnd');

						}else {
							$target.closest(".tab-cont").removeClass("active");
							$target.closest(".component").find(".no-content-wrap").addClass("active");

							$form.trigger('ajaxLoadEnd');
						}
						// console.log($target);
					});
				}
			}, 'form');

			$(_this.el).find('[data-user-type=""], [data-user-type=ALL], [data-user-type=NON_VIP]').removeClass('d-none'); // LGEIS-800

			// over, out, focus, blur 이벤트 - ajax 호출 안되는 경우 (1번째 탭)

			// mouseover (default)
			$(_this.el).find(".item a.visual").off("mouseenter").on("mouseenter", function(){ // WA-GPC0003-09
				$(this).closest(".item").addClass("on-focus");
				$(this).closest(".item").siblings().removeClass('on-focus');
			});
			$(_this.el).find(".item").off("mouseleave").on("mouseleave", function(e){ // WA-GPC0003-09
				//$(this).removeClass("on-focus");
				$(_this.el).find(".item").removeClass("on-focus");
			});
			
			// WA-GPC0003-09
			setTimeout(function() { // fix bv script
				$(_this.el).find('.item').each(function() {
					var $blurlink = $(this).find('.model-name a');
					if($(this).find('a.bv_main_container').length>0) $blurlink = $(this).find('a.bv_main_container');
					if($(this).find('.rating.inhouse-review').length>0) $blurlink = $(this).find('.rating.inhouse-review > a');
					if($(this).find('.rating.loaded').length>0) $blurlink = $(this).find('.rating.loaded a');
					$blurlink.off('blur.showbtn').on('blur.showbtn', function(e) {
						$(this).closest(".item").addClass("on-focus");
						$(this).closest(".item").siblings().removeClass('on-focus');
						// 20200401 START 박지영 : 모바일에서 불필요한 스크립트 동작하지 않도록 수정 
						if($(this).closest(".item").find('.button a').length>0) {
							if($(window).width() >= 768) $(this).closest(".item").find('.button a').eq(0).focus();
						} else if($(this).closest(".item").find('.wishlist-compare a').length>0) {
							if($(window).width() >= 768) $(this).closest(".item").find('.wishlist-compare a').eq(0).focus();
						}
						// 20200401 END
					});
				});

				// - over, out, focus, blur 이벤트 - ajax 호출 안되는 경우 (1번째 탭)
			}, 1000);
			$(_this.el).find(".item a.visual").off("focus.showbtn").on("focus.showbtn", function(e){
				$(this).removeClass("on-focus");
			});

			// tab click
			$(_this.tab).on({
				click: function(e){
					e.preventDefault();
					var target = $(e.currentTarget.getAttribute('href')).get(0),
						targetForm = target.querySelector('form');

					// hide no-content area
					$(target).closest(".component").find(".no-content-wrap").removeClass("active");

					if($(targetForm).length>0) {
						if(!$(target.querySelector('.list-contents-wrap')).is('.initialized')) {
							if($(targetForm).find('input[name=type][value=EO]').length>0 || $(targetForm).find('input[name=type][value=YMAL]').length>0) {
								if(typeof OBS.init == 'function') {
									OBS.init($(target));
								} else {
									$(targetForm).submit();
								}
							} else {
								$(targetForm).submit();
							}
						}
					} else {
						if(!$(target).hasClass('active')) {
							$(target).addClass('active');
						}
						if(!$(target.querySelector('.list-contents-wrap')).find('.unit-list').hasClass('slick-initialized')) {
							setTimeout(function() {
								$(target.querySelector('.list-contents-wrap')).find('.unit-list').slick(GPC0003.opt);
								runBVStaticPLP($(target));
								
								/* LGECN-214 Start */
								if(lgCggroupName == 'home' && COUNTRY_CODE.toLowerCase() == 'cn' && typeof _hmt != 'undefined'){
									$obj.find('button').off('click.baidu').on('click.baidu', function(){
										if($(this).hasClass('slick-prev')){
											console.log('[Baidu trackEvent] [Homepage_Product Impression] [Click Previous]', ', $lg_cggroup : '+ $lg_cggroup);
											_hmt.push(['_trackEvent',$lg_cggroup,'Click Previous','Homepage_Product Impression']);
										}else if($(this).hasClass('slick-next')){
											console.log('[Baidu trackEvent] [Homepage_Product Impression] [Click Next]', ', $lg_cggroup : '+ $lg_cggroup);
											_hmt.push(['_trackEvent',$lg_cggroup,'Click Next','Homepage_Product Impression']);
										}
									});
								}
								/* LGECN-214 End */
								
								// over, out, focus, blur 이벤트 - 탭 클릭시
								$(target).find(".item a.visual").off("mouseenter").on("mouseenter", function(){
									$(target).find(".item").removeClass("on-focus");
									$(this).closest(".item").addClass("on-focus");
								});
								$(target).find(".item").off("mouseleave").on("mouseleave", function(e){
									$(this).removeClass("on-focus");
								});
								setTimeout(function() { // fix bv script
									$(target).find('.item').each(function() {
										var $blurlink = $(this).find('.model-name a');
										if($(this).find('a.bv_main_container').length>0) $blurlink = $(this).find('a.bv_main_container');
										if($(this).find('.rating.inhouse-review').length>0) $blurlink = $(this).find('.rating.inhouse-review > a');
										if($(this).find('.rating.loaded').length>0) $blurlink = $(this).find('.rating.loaded a');
										$blurlink.off('blur.showbtn').on('blur.showbtn', function(e) {
											$(this).closest(".item").addClass("on-focus");
											$(this).closest(".item").siblings().removeClass('on-focus');
											// 20200401 START 박지영 : 모바일에서 불필요한 스크립트 동작하지 않도록 수정 
											if($(this).closest(".item").find('.button a').length>0) {
												if($(window).width() >= 768) $(this).closest(".item").find('.button a').eq(0).focus();
											} else if($(this).closest(".item").find('.wishlist-compare a').length>0) {
												if($(window).width() >= 768) $(this).closest(".item").find('.wishlist-compare a').eq(0).focus();
											}
											// 20200401 END
										});
									});
								}, 1000);
								$(target).find(".item a.visual").off("focus.showbtn").on("focus.showbtn", function(){
									$(target).find(".item").removeClass("on-focus");
								});
								// -- over, out, focus, blur 이벤트 - 탭 클릭시
								/* LGEITF-604 Start */
								if(e.originalEvent != undefined){
									if (!!!vipCheckGPC0003 || vipCheckGPC0003 == 'false' || $obj.attr('data-is-vip') == 'NULL') {
										priceSyncGPC0003.init(target);
									} else {
										$('.GPC0003').find('.products-info .price-wt-box').find('.price-vip-Installment, .price-area, .member-text').removeClass('d-none');
									}
								}else{
									if($obj.find('input[name=priceSyncLazyload]').val() == 'N') priceSyncLazyloadGPC0003();
								}
								/* //LGEITF-604 End */

							}, 100);
						}
					}
					/* LGECN-214 Start */
					if(lgCggroupName == 'home' && COUNTRY_CODE.toLowerCase() == 'cn' && typeof _hmt != 'undefined'){
						var $clickContent = $(this).text();
						console.log('[Baidu trackEvent] [Homepage_Product Impression]', ', $lg_cggroup : '+ $lg_cggroup + ', $clickContent : '+$clickContent);
						_hmt.push(['_trackEvent',$lg_cggroup,'Click '+$clickContent,'Homepage_Product Impression']);
					}
					/* LGECN-214 End */
				}
			}, 'a');
			
			/* LGECN-214 Start */
			if(lgCggroupName == 'home' && COUNTRY_CODE.toLowerCase() == 'cn' && typeof _hmt != 'undefined'){
				$(_this.el).find('.list-contents-wrap').on('click', 'button, a', function(){
					if(!!$(this).parents('.item').length){
						var list_name = $(_this.tab).find('a.active').text();
						var product_name = $(this).parents('.item').find('.model-name').text();
						var product_sku = $(this).parents('.item').find('.visual').data('adobe-modelname');
						var product_category = $(this).parents('.item').find('.visual').data('sibling-code');
						console.log('[Baidu trackEvent] [select_product] ', ', list_name : '+ list_name, ', product_name : '+product_name, ', product_sku : ' + product_sku, ', product_category : '+product_category);
						_hmt.push(['_trackCustomEvent', 'select_product', {
						    'list_name': list_name,
						    'product_name': product_name,
						    'product_sku': product_sku,
						    'product_category': product_category
						}]);
					}
				});
			}
			/* LGECN-214 End */

			// resize
			var resizeEvent = function(e) {
				if (e.matches) { // if matches media query 
					//console.log(e.matches);
				} else { // if not  }
					//console.log(e.matches);
					// over, out, focus, blur 이벤트 - 리사이징 시 (only desktop)

					setTimeout(function() {
						$(GPC0003.el).find(".item a.visual").off("mouseenter").on("mouseenter", function(){
							$(GPC0003.el).find(".item").removeClass("on-focus");
							$(this).closest(".item").addClass("on-focus");
						});
						$(GPC0003.el).find(".item").off("mouseleave").on("mouseleave", function(e){
							$(this).removeClass("on-focus");
						});
						$(GPC0003.el).find(".item a.visual").off("focus.showbtn").on("focus.showbtn", function(){
							$(GPC0003.el).find(".item").removeClass("on-focus");
						});
					}, 500);

					setTimeout(function() { // fix bv script
						$(GPC0003.el).find('.item').each(function() {
							var $blurlink = $(this).find('.model-name a');
							if($(this).find('a.bv_main_container').length>0) $blurlink = $(this).find('a.bv_main_container');
							if($(this).find('.rating.inhouse-review').length>0) $blurlink = $(this).find('.rating.inhouse-review > a');
							if($(this).find('.rating.loaded').length>0) $blurlink = $(this).find('.rating.loaded a');
							$blurlink.off('blur.showbtn').on('blur.showbtn', function(e) {
								$(this).closest(".item").addClass("on-focus");
								$(this).closest(".item").siblings().removeClass('on-focus');
								// 20200401 START 박지영 : 모바일에서 불필요한 스크립트 동작하지 않도록 수정 
								if($(this).closest(".item").find('.button a').length>0) {
									if($(window).width() >= 768) $(this).closest(".item").find('.button a').eq(0).focus();
								} else if($(this).closest(".item").find('.wishlist-compare a').length>0) {
									if($(window).width() >= 768) $(this).closest(".item").find('.wishlist-compare a').eq(0).focus();
								}
								// 20200401 END
							});
						});
					}, 1000);

					// -- over, out, focus, blur 이벤트 - 리사이징 시 (only desktop)
					
				}
			};
			mql.maxXs.addListener(resizeEvent);
			//resizeEvent(mql.maxXs); // initial run

		}
	};
	//LGEGMC-383
	//file download
	var fileDownload = function($link) {
		if($('form#formDown').length>0) $('form#formDown').remove();

		var fileBox = $link.closest('[data-file-download]');
		var flag = (fileBox.data('flag') || '').toUpperCase();
		var downtime = fileBox.data('downtime') || null;
		var opentime = fileBox.data('opentime') || null;

		if(flag=='Y') {
			// downtime error
			var $errorPop = $('#htmlOpenError');
			if($errorPop.length>0) {
				$errorPop.modal();
				$errorPop.find('.htmldowntime').text(downtime);
				$errorPop.find('.htmlopentime').text(opentime);
			} else {
				console.log('#htmlOpenError is required in HTML');
			}
		} else {

			var doc = $link.data('doc'),
				file = $link.data('file'),
				original = $link.data('original'),
				category = $link.data('category');

			var input = '<input type="hidden" id="DOC_ID" name="DOC_ID" value="' + doc +'" />';
			input += '<input type="hidden" id="ORIGINAL_NAME_b1_a1" name="ORIGINAL_NAME_b1_a1" value="' + original +'" />';
			input += '<input type="hidden" id="FILE_NAME" name="FILE_NAME" value="' + file +'" />';
			input += '<input type="hidden" id="TC" name="TC" value="DwnCmd" />';
			input += '<input type="hidden" id="GSRI_DOC" name="GSRI_DOC" value="GSRI" />'
			input += '<input type="hidden" id="SPEC_DOWNLOAD" name="SPEC_DOWNLOAD" value="N" />';
			/*LGEGMC-1035 start*/
            var openlocale="|AR|AT|BE_FR|BG|CH_DE|CH_FR|CZ|DE|DK|EE|ES|FI|FR|GR|HR|HU|IT|LT|LV|NL|NO|PL|PT|RO|RS|SE|SK|UK|";
            var _target="_self";
             if((original.toLowerCase().indexOf('.pdf')>-1 || original.toLowerCase().indexOf('.bmp')>-1 || original.toLowerCase().indexOf('.jpg')>-1 || original.toLowerCase().indexOf('.jpeg')>-1||original.toLowerCase().indexOf('.png')>-1) && openlocale.indexOf("|"+COUNTRY_CODE.toUpperCase()+"|")>-1){
            	_target="_blank";
            }
            
			var form = $('<form />').attr({
				id: 'formDown',
				method: 'get',
				action: fileBox.data('action'),
				target: _target
			}).append(input);
			$('body').append(form);
			/*LGEGMC-1035 end*/

			$('#formDown').submit();
		}
	}
	
	
	$obj.on('click','.file-list a.link-text',function(e){
		e.preventDefault();
		fileDownload($(this));
	});
	

	GPC0003.init();
});
/* LGCOMSPEED-6(6th) Start */
/* LGEITF-604 Start */
let priceSyncGPC0003 = {
	init: function($target){
		this.getPrice($target);
		
	},
	getPrice: function($target){
		var $obj = $('.GPC0003');
		let arrModelId = new Array();
		const $targetSelector = $($target.querySelector('.list-contents-wrap')); 
		$targetSelector.find('input[name=bestActionAreaModelId]').each(function(idx) {
			arrModelId[idx] = $(this).val();
		});
		
		const _this = this,
			url = $obj.data('baa-home-price-sync-url'),
			param = {
				modelList: arrModelId.length > 0 ? arrModelId.join() :'',
				componentId: $obj.data('component-id'),
				type: $(GPC0003.tab.querySelector('a.active')).data('nba-type'),
				bizType: $obj.data('biz-type')
			};
			
		if (!!param.modelList) {
			ajax.noCacheCall(url, param, 'json', function(data){
				if(data.status == 'success' && data.data){
					let comonData = {};
					comonData.emiPopupUrl = data.data[0].emiPopupUrl;
					comonData.obsMembershipLinkUrl = data.data[0].obsMembershipLinkUrl;
					comonData.obsMembershipLinkTarget = data.data[0].obsMembershipLinkTarget;
					comonData.obsMembershipLinkUseFlag = data.data[0].obsMembershipLinkUseFlag;
					comonData.limitSaleTitle = data.data[0].productMessages.limitSaleTitle;
					comonData.obsCalculatorUseFlag = data.data[0].obsCalculatorUseFlag;
					comonData.obsInstallmentPromotionUseFlag = data.data[0].obsInstallmentPromotionUseFlag;
					comonData.obsInstallmentPriceDisableLink = data.data[0].obsInstallmentPriceDisableLink;	// LGETW-651 add
					/* LGCOMPA-44 start */
					if(!!data.data[0].guestPriceMessage && !!data.data[0].guestPriceMessageUseFlag){						
						comonData.guestPriceMessage = data.data[0].guestPriceMessage;
						comonData.guestPriceMessageUseFlag = data.data[0].guestPriceMessageUseFlag;
					}
					/* LGCOMPA-44 end */
					
					for(var i=0; i<data.data[0].productList.length; i++){
						const p = data.data[0].productList[i],
							priceSyncHtml = GPC0003.priceTemplateGPC003;
						
						if (priceSyncHtml) {
							const $template = _this.makePriceSyncHtml(p, comonData, priceSyncHtml);
							const scop = $targetSelector.find('.unit-list .slick-track .item').find('.products-info[data-model-id='+p.modelId+']');
							scop.find('.price-vip-Installment, .price-area, .member-text,.lowest-price, .coupon-price').remove(); // LGCOMMON-54 LGCOMPL-218 LGCOMITF-69
							scop.find('.price-wt-box').append($template);
						}
					}
				}
			});
		}
	},
	makePriceSyncHtml : function(p, comonData, template){
		if (template) {
			const emiMsgFlag = !!p.obsEmiMsgFlag && p.obsEmiMsgFlag == 'Y',
				afterPay = p.afterPay,
				obsMembershipLinkTarget = comonData.obsMembershipLinkTarget,
				obsMembershipLinkUrl = comonData.obsMembershipLinkUrl,
				obsCalculatorUseFlag = !!comonData.obsCalculatorUseFlag && comonData.obsCalculatorUseFlag == 'Y',
				obsInstallmentPromotionUseFlag = !!comonData.obsInstallmentPromotionUseFlag && comonData.obsInstallmentPromotionUseFlag  == 'Y',
				rPrice = p.rPrice,
				rPriceCent = p.rPriceCent,
				rPromoPrice = p.rPromoPrice,
				rPromoPriceCent = p.rPromoPriceCent,
				discountMsg = p.discountMsg,
				bizType = p.bizType,
				obsLoginFlag = !!p.obsLoginFlag && p.obsLoginFlag ? 'Y' : 'N',
				limitSaleUseFlag= !!p.limitSaleUseFlag && p.limitSaleUseFlag == 'Y',
				obsLimitSale = !!p.obsLimitSale && p.obsLimitSale == 'Y',
				modelStatusCode = p.modelStatusCode,
				title = obsMembershipLinkTarget == '_blank' ? $('.GPC0003 > input#openTarget').val() : '';
				
			let tempPrice = '',
				tempPromoPrice = '',
				tempLegal = '',
				obsMemberShipLinkStartHtml = '',
				obsMemberShipLinkEndHtml = '';
			
			if (!!obsMembershipLinkUrl && p.membershipDisplayFlag == 'Y') {
				obsMemberShipLinkStartHtml = "<a href='"+ obsMembershipLinkUrl +"' target='"+ obsMembershipLinkTarget +"' title='" +title+ "'>";
				obsMemberShipLinkEndHtml = "</a>"
			} 
			
			template = template.replace(/\*modelId\*/g, p.modelId)
			.replace(/\*retailerPricingText\*/g, !!p.retailerPricingText ? p.retailerPricingText : '')
			.replace(/\*emiPopUrl\*/g, !!comonData.emiPopupUrl ? comonData.emiPopupUrl : '')
			.replace(/\*emiMsg\*/g, emiMsgFlag ? p.emiMsg : '')
			.replace(/\*display\*/g, emiMsgFlag && (afterPay > 0 && afterPay <= 3000) ? '' : 'style="display:none;"')
			.replace(/\*zipdisplay\*/g, emiMsgFlag && (afterPay > 0 && afterPay <= 10000) ? '' : 'style="display:none;"') /* OBSLGEAU-749 (5000 -> 10000) */
			.replace(/\*orZipPay\*/g, emiMsgFlag && (afterPay > 0 && afterPay <= 3000) ? 'or ' : '')
			.replace(/\*msrp\*/g, nvl(p.msrp,'0'))
			.replace(/\*calculatorSalesCode\*/g, !!p.salesSuffixCode ? p.salesModelCode+"."+p.salesSuffixCode : p.salesModelCode)
			.replace(/\*obsMembershipLinkUrl\*/g, !!obsMembershipLinkUrl ? comonData.obsMembershipLinkUrl : '')
			.replace(/\*obsMembershipLinkTarget\*/g, !!obsMembershipLinkTarget ? obsMembershipLinkTarget : '')
			.replace(/\*obsMembershipPrice\*/g, !!p.rMembershipPriceCent ? changeFormatFullPriceSync(p.rMembershipPrice, p.rMembershipPriceCent) : (!!p.rMembershipPrice ? changeFormatPriceSync(p.rMembershipPrice) : ''))
			.replace(/\*obsMemberShipLinkStart\*/g,obsMemberShipLinkStartHtml)
			.replace(/\*obsMemberShipLinkEnd\*/g,obsMemberShipLinkEndHtml)
			//LGCOMITF-69 START
			.replace(/\*obsWelcomePrice\*/g, !!p.obsWelcomePriceUseFlag && 'Y' == p.obsWelcomePriceUseFlag ? changeFormatFullPrice(p.obsWelcomePrice, p.obsWelcomePriceCent) : '') //obsWelcomePriceUseFlag가 Y면 obsWelcomePrice, obsWelcomePriceDescription에 값이 존재함
			.replace(/\*obsWelcomePriceDescription\*/g, !!p.obsWelcomePriceUseFlag && 'Y' == p.obsWelcomePriceUseFlag ? p.obsWelcomePriceDescription : '')
			//LGCOMITF-69 END
			;
			
			// Price
			if (modelStatusCode == 'ACTIVE') {
				if (!!rPromoPriceCent) {
					tempPrice = changeFormatFullPriceSync(rPromoPrice, rPromoPriceCent);
					tempPromoPrice = changeFormatFullPriceSync(rPrice, rPriceCent);
					tempLegal = discountMsg;
				} else if (!!rPromoPrice) {
					tempPrice = changeFormatFullPriceSync(rPromoPrice);
					tempPromoPrice = changeFormatFullPriceSync(rPrice);
					tempLegal = discountMsg;
				} else if (!!rPriceCent) {
					tempPrice = changeFormatFullPriceSync(rPrice, rPriceCent);
				} else if (!!rPrice) {
					tempPrice = changeFormatFullPriceSync(rPrice);
				}
			}
			
			let $html = $(template);
			
			if (bizType == 'B2B' && obsLoginFlag == 'Y' && !!rPrice) {
				$html.find('.price-area > div.msrp').remove();
			}
			if (!!p.membershipDisplayFlag && p.membershipDisplayFlag != 'Y') {
				$html.find('.member-text').remove();
			
			/* LGCOMPA-44 start */
			} else {
				if(  SIGN_IN_STATUS == 'N'
				  && !!comonData.guestPriceMessageUseFlag
				  &&   comonData.guestPriceMessageUseFlag == 'Y'
				  && !!comonData.guestPriceMessage ){
					$html.find('.member-text').html('').append(comonData.guestPriceMessage);
				}
			/* LGCOMPA-44 end */
			}

			if (!!p.retailerPricingFlag && p.retailerPricingFlag != 'Y') {
				$html.find('.price-area > div.retailer').remove();
			}
			if (afterPay <= 0 || p.obsInventoryFlag != 'Y') {
				$html.find('.price-vip-Installment > a.afterpay-installment').remove();
			}
			if (modelStatusCode != 'ACTIVE' || (!!!rPrice && !!!rPromoPrice) || p.retailerPricingFlag == 'Y') {
				$html.find('.purchase-price, .product-price').remove();
			} else if (!!rPrice && !!!rPromoPrice) {
				$html.find('.product-price').remove();
			}
			
			if (modelStatusCode == 'ACTIVE') {
				$html.find('.purchase-price .number').text(tempPrice);
				$html.find('.product-price .number').text(tempPromoPrice);
				$html.find('.product-price .legal').text(tempLegal);
			}
			if (limitSaleUseFlag && obsLimitSale) {
				//LGCOMIS-121
				var countryCode = COUNTRY_CODE.toLowerCase();
				if(countryCode == 'it'){
					$html.find('.price-vip-Installment .price-vip').text('');
				}else{
					$html.find('.price-vip-Installment .price-vip').text(comonData.limitSaleTitle);
				}
			}
			if (obsCalculatorUseFlag && obsInstallmentPromotionUseFlag) {
				$html.find('.price-installment.none-calc').remove();
			} else {
				$html.find('.price-installment.calc').remove();
			}
			
			/* LGETW-651 start */
			if(!!comonData.obsInstallmentPriceDisableLink && comonData.obsInstallmentPriceDisableLink == 'Y'){
				$html.find('.price-installment').addClass('no-link').attr('tabindex','-1');
			}
			/* LGETW-651 end */
			
			// LGCOMMONPL-218  start
			if ( p.obsLowestPriceFlag == 'Y' && !!p.rPromoPrice ) {
				if ( $html.find('.lowest-price-mark').length ) $html.find('.lowest-price-mark').removeClass('d-none');
				if ( $html.find('.lowest-price').length ) $html.find('.lowest-price').removeClass('d-none'); 
			} else {
				if ( $html.find('.lowest-price-mark').length ) $html.find('.lowest-price-mark').remove();
				if ( $html.find('.lowest-price').length ) $html.find('.lowest-price').remove();
			}
			// LGCOMMONPL-218  end
			
			//LGCOMITF-69 START
			if(!!p.obsWelcomePriceUseFlag && 'Y' == p.obsWelcomePriceUseFlag){
				$html.find('.coupon-price').removeClass('d-none');
			}else{
				$html.find('.coupon-price').html('');
			}
			//LGCOMITF-69 END
			
			return $html.html();
		}
	}
}
/* //LGEITF-604 End */

function priceSyncLazyloadGPC0003(){
	if(!document.querySelector('.GPC0003')) return false;
	var $obj = $('.GPC0003');
	/* LGEITF-604 Start */
	var observer = new MutationObserver(function(mutations) {
		mutations.forEach(function(mutation) {
			if (mutation.attributeName === 'data-is-vip') {
				vipCheckGPC0003 = mutation.target.getAttribute('data-is-vip');
				priceSyncSet(mutation.target.getAttribute('data-is-vip'));
				observer.disconnect();
			}
		});
	});
	observer.observe(document.querySelector('.GPC0003'), {
		attributes: true, childList: false, characterData: false
	});
	
	if($obj.attr('data-is-vip') != 'NULL'){
		vipCheckGPC0003 = $obj.attr('data-is-vip');
		priceSyncSet($obj.attr('data-is-vip'));
		observer.disconnect();		
	}
	/* //LGEITF-604 End */
	
	function priceSyncSet(flag){
		if(flag == 'false'){
			let timer = null;
			
			function checkGPC0003() {
				if (typeof GPC0003 === 'undefined') {
					timer = setTimeout(checkGPC0003, 100);
				} else {
					if(timer !== null) clearTimeout(timer);
					$obj.each(function(){
						priceSyncGPC0003.init($(this).find('.products-list-group2')[0]);
					});
				}
			}
			checkGPC0003();
		} else {
			$obj.find('.products-info .price-wt-box').find('.price-vip-Installment, .price-area, .member-text').removeClass('d-none');
		}
	}
}
if(document.readyState == 'complete') {
	if($('.GPC0003').find('input[name=priceSyncLazyload]').val() !== 'N') priceSyncLazyloadGPC0003();
}
window.addEventListener("load", function(){
	if($('.GPC0003').find('input[name=priceSyncLazyload]').val() !== 'N') priceSyncLazyloadGPC0003();
});
/* LGCOMSPEED-6(6th) End */